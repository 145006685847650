import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


import { Container, Row, Col, Carousel } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="Weddings" />

    <Carousel interval={5000}>
      <Carousel.Item>
        <StaticImage
          src="../images/weddings/01-weddings.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Weddings"
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/weddings/02-weddings.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Weddings"
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/weddings/03-weddings.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Weddings"
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/weddings/04-weddings.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Weddings"
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/weddings/05-weddings.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Weddings"
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/weddings/06-weddings.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Weddings"
        />
      </Carousel.Item>
      
    </Carousel>
    <div className="hawaiianBG d-flex justify-content-center py-3">
      <h1>Weddings</h1>
    </div>
    <Container className="p-4">
    
    
    <Row className="g-5">
      <Col lg={8}>
        <p>
          The Cook Islands is one of the most popular and romantic places for weddings in the Pacific.  Rarotonga is well known for its pristine white beaches and lush tropical forestry making it the perfect setting for some of the most beautiful island weddings in the world.
        </p>
        <p>
          The stunning blue lagoon framed with the tropical island background will provide an amazing backdrop for those memorable wedding photos. 
        </p>
        <p>
          Often forgotten is organising how to get to and from your wedding location.  Whatever your plans, let us take care of you in our special white wedding e-tuk limo that will have you and your wedding party arriving in style.
        </p>
        <p>
          For larger weddings, we have a fleet of vehicles that can help transport your guests to and from your special location.
        </p>
        <p>
          Our e-tuk limos are also available for bachelor and hens nights.
        </p>
        <p>
          Feel free to contact us directly or we can liaise with your wedding planner to ensure you have the most memorable occasion.
        </p>
        

        <p>
          <StaticImage
            src="../images/flowers.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Flowers"
          />
        </p>

      </Col>
      <Col lg={4}>
        <p className="lead">Recent Press</p>
        <p>
          <a href="http://www.nzherald.co.nz/travel/news/article.cfm?c_id=7&objectid=11731227">
            <StaticImage
              src="../images/nz-herald.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://www.usatoday.com/story/travel/destinations/2017/04/01/cook-islands/99882942/">
            <StaticImage
              src="../images/large_USAToday_Logo_Square.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://kiwiliving.nz/holidays/mike-in-rarotonga">
            <StaticImage
              src="../images/kiwi-living.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        
      </Col>
    </Row>
    
    </Container>
      
  </Layout>
)

export default IndexPage
